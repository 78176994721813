// CreateUserModal.js
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const CreateUserModal = ({ show, onHide, onCreateUser }) => {
  const [userData, setUserData] = useState({
    lastname: '',
    firstname: '',
    email: '',
    accesslevel: '',
    password: '',  
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleCreateUser = () => {
    // Check if the password and confirm password match
    if (userData.password !== userData.confirmpassword) {
      alert('Password and Confirm Password do not match');
      return;
    }

    // Ensure accesslevel is within the allowed range (1, 2, 3, 4, or 5)
    if (![1, 2, 3, 4, 5].includes(Number(userData.accesslevel))) {
      alert('Access Level must be 1, 2, 3, 4, or 5');
      return;
    }

    // Call the parent component's onCreateUser function and pass the userData
    onCreateUser(userData);
    setUserData({
      lastname: '',
      firstname: '',
      email: '',
      accesslevel: '',
      password: '',  
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Create user form fields */}
        <div className="form-group">
          <label htmlFor="lastname">Last Name</label>
          <input
            type="text"
            className="form-control"
            id="lastname"
            name="lastname"
            value={userData.lastname}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="firstname">First Name</label>
          <input
            type="text"
            className="form-control"
            id="firstname"
            name="firstname"
            value={userData.firstname}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={userData.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="accesslevel">Access Level (1-5)</label>
          <input
            type="number"
            className="form-control"
            id="accesslevel"
            name="accesslevel"
            value={userData.accesslevel}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            value={userData.password}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmpassword">Confirm Password</label>
          <input
            type="password"
            className="form-control"
            id="confirmpassword"
            name="confirmpassword"
            value={userData.confirmpassword}
            onChange={handleInputChange}
          />
        </div>

        
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleCreateUser}>
          Add User
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateUserModal;
