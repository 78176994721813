import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap'; 
import './Header.css';
import {useNavigate} from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import ChangePasswordModal from './ChangePasswordModal';



const Header = ({ onSidebarToggle }) => {
  const { logout, firstName, lastName, userId } = useAuth();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleShowChangePassword = () => {
    setShowChangePasswordModal(true);
  };

  const handleCloseChangePassword = () => {
    setShowChangePasswordModal(false);
  };

  const handleSuccess = () => {
    handleCloseChangePassword();
  };

  return (
    <header className="header d-flex justify-content-between align-items-center border-bottom">
      <button className="sidebar-toggle btn btn-link" onClick={onSidebarToggle}>
        <span className="bi bi-list"></span> 
      </button>
      <Dropdown>
        <Dropdown.Toggle variant="link" id="avatar-dropdown" className="text-decoration-none">
        <div className="d-flex gap-2 align-items-center">
          <span className="user-name text-dark">{`${firstName} ${lastName}`}</span>
          <span className="bi bi-person-circle user-icon"></span>   
        </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
        <Dropdown.Item onClick={handleShowChangePassword}>
            Change Password
          </Dropdown.Item>
          <Dropdown.Item onClick={handleLogout} >Log Out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      
      <ChangePasswordModal
        show={showChangePasswordModal}
        onHide={handleCloseChangePassword}
        userId={userId}
        onChangePassword={handleSuccess}  />
    </header>
  );
};

export default Header;
