import React from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import Survey from '../components/Survey';
import axios from 'axios';
import SurveyNav from '../components/SurveyNav';
import Footer from '../components/Footer';

const SurveyForm = () => {
  const navigate = useNavigate();
  const { customerName, facilityId, serviceDate} = useParams();

  const handleSurveySubmit = (data) => {
    // Here, you can handle the form data as needed, e.g., send it to the server
    console.log('Form submitted:', 
    customerName,
    facilityId,
    serviceDate,
    data);

    const surveyData = data;
    surveyData.customerName = customerName;
    surveyData.facilityId = facilityId;
    surveyData.serviceDate= serviceDate;

    const url = process.env.REACT_APP_SERVER_URL;
    

    axios.put(url+'/survey', surveyData)
        .then((res) => {
            console.log(res.data)
        }).catch((error) => {
          console.log(error)
        });


    // Navigate to the thank-you page after submission
    navigate('/thank-you');
  };

  return (
    <div className="container mt-5 mb-5">
      <SurveyNav />
      <h4 className="mb-3">Customer Satisfaction Survey</h4>
      <p className="text-start">The InterHealth Group of Companies thanks you for the opportunity to serve you. We kindly ask you to take a moment to tell us about your experience and how well our service was in meeting your expectations so we can continue to improve and better serve you!</p>     
      <Survey onSubmit={handleSurveySubmit} /> 
      <Footer />
    </div>
  );
};

export default SurveyForm;
