import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import SurveyDashboardCards from '../components/SurveyDashboardCards';
import SurveysListing from '../components/SurveysListing';
import 'bootstrap/dist/css/bootstrap.min.css';


const SurveyDashboard = () => {
  const crumbs = [
    { label: 'Home', path: '/' },
    { label: 'Survey Dashboard', path: '/surveydashboard' },
  ];
  return (
    <div className="px-2 bg-light">
      <Breadcrumbs crumbs={crumbs} />
      <div className="">
        <SurveyDashboardCards />
      </div>
      <div className="bg-white border rounded my-4 p-2">
          <SurveysListing />
      </div>
    </div>
  )
}

export default SurveyDashboard