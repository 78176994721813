import React from 'react';
import spectrumlogo from '../images/spectrumLogoSm.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const SpectrumLogo = () => {
  return (
    <div className=''>
      <img src={spectrumlogo} alt="spectrumlogo" className="img-fluid"/>
    </div>
  )
}

export default SpectrumLogo
