import React from 'react';
import SurveysTable from './SurveysTable';

const SurveysListing = () => {
  return (
    <div>
      <h4>Surveys</h4>
      <SurveysTable />
    </div>
  );
};

export default SurveysListing;