import React from 'react';
import aamglogo from '../images/aamgLogoSm.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const AamgLogo = () => {
  return (
    <div>
      <img src={aamglogo} alt="aamglogo" className="img-fliud"/>
    </div>
  )
}

export default AamgLogo
