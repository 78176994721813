import React from 'react';
import InterHealthLogo from './InterHealthLogo';
import AamgLogo from './AamgLogo';
import SpectrumLogo from './SpectrumLogo';
import BmlsLogo from './BmlsLogo';
import 'bootstrap/dist/css/bootstrap.min.css';

const SurveyNav = () => {
  return (
   <>
    <nav className="navbar navbar-expand-md navbar-light my-5  border-bottom">
        <div className="container  justify-content-center">
            <a className="navbar-brand" href="/">
                <div className="d-flex flex-row flex-wrap">
                    <div className="d-flex">
                        <div className=""><InterHealthLogo /></div>
                        <div className=""><AamgLogo /></div>
                    </div>
                    
                    <div className="d-flex">
                        <div className=""><SpectrumLogo /></div>
                        <div className=""><BmlsLogo /></div>
                    </div>
                    
                </div>
                
            </a>
        </div>
    </nav>
   </>
  )
}

export default SurveyNav