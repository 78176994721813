// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DashboardCards.css */
.custom-row {
    margin: -1rem; /* Add negative margin to counteract Bootstrap's default row gutter */
  }
  
.custom-row > div {
    padding: 1rem; /* Add padding to individual columns for spacing */
  }

  .custom-card-xs {
    width: 75%; /* Reduce the width to 75% on XS screens */
    margin: 0 auto; /* Center the card horizontally on XS screens */
  }`, "",{"version":3,"sources":["webpack://./src/components/SurveyDashboardCards.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,aAAa,EAAE,qEAAqE;EACtF;;AAEF;IACI,aAAa,EAAE,kDAAkD;EACnE;;EAEA;IACE,UAAU,EAAE,0CAA0C;IACtD,cAAc,EAAE,+CAA+C;EACjE","sourcesContent":["/* DashboardCards.css */\n.custom-row {\n    margin: -1rem; /* Add negative margin to counteract Bootstrap's default row gutter */\n  }\n  \n.custom-row > div {\n    padding: 1rem; /* Add padding to individual columns for spacing */\n  }\n\n  .custom-card-xs {\n    width: 75%; /* Reduce the width to 75% on XS screens */\n    margin: 0 auto; /* Center the card horizontally on XS screens */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
