import React from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';

const DeleteUserModal = ({ show, onHide, user, fetchUpdatedUsers, headers }) => {
    const handleDeleteUser = async () => {
        if (user) {
          try {
            
            // Make an API call to delete the pensioner record
            const response = await axios.delete(process.env.REACT_APP_SERVER_URL+`/users/${user._id}`,
            {
                headers: headers,
              });
      
            if (response.status === 200) {
            // Fetch updated user data
            console.log('User deleted successfully:', response.data);
            toast.success('Record deleted successfully');
            fetchUpdatedUsers();
      
            // Close the delete confirmation modal
            onHide();
            } else {
                console.log('User delete failed:', response.data);
                toast.error('User delete failed');
            }
          } catch (error) {
            console.error('Error deleting user:', error);
            toast.error('An error occurred while deleting the record');
          }
        }
      };

    return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {user && (
            <p>
                Are you sure you want to delete {user.lastname},{' '}
                {user.firstname}?
            </p>
            )}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
            No
            </Button>
            <Button variant="danger" onClick={handleDeleteUser}>
            Yes
            </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default DeleteUserModal;