import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../images/interhealthLogoSm.png";
  

const Login = () => {
  const { isAuthenticated, setIsAuthenticated, token, login, logout } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(process.env.REACT_APP_SERVER_URL+'/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.success) {
          login(data.accessToken, data.refreshToken);
          
          
          navigate("/surveydashboard");
          console.log(data);
          console.log('logged in');
        } else {
          console.log('Login failed:', data.message);
          toast.error('Login failed');
        }
      } else {
        console.log('Network response was not ok.');
        toast.error('Login failed: Please check your credentials and retry.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while logging in. Please Retry.');
    }
  };
  return (
    <>    
    
    <ToastContainer/>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card my-5">
            <div className="d-flex flex-row card-header text-dark align-items-end gap-5">
            <div>
              <img src={logo} alt="Logo" className="logo-image" />
              </div>
              <div className="">
                Login:
              </div>
             
            </div>
            <div className="card-body">
              <div className="text-small text-center">
                <p>Enter your login credentials to access the application:</p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">Email:</label>
                  <input
                    type="email" 
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Password:</label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary">Login</button>
              </form>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Login