import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SurveyDashboardCards.css';
import FacilitySurveyChart from './FacilitySurveyChart';

const SurveyDashboardCards = () => {
  const [aamgSurveys, setAamgSurveys]=useState([]);
  const [spectrumSurveys, setSpectrumSurveys]=useState([]);
  const [bmlsSurveys, setBmlsSurveys]=useState([]);
  const [allSurveys, setAllSurveys]=useState([]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/aamgsurveys')
      .then(response => {
        setAamgSurveys(response.data);
      })
      .catch(error => {
        console.error('Error fetching AAMG Surveys:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/spectrumsurveys')
      .then(response => {
        setSpectrumSurveys(response.data);
      })
      .catch(error => {
        console.error('Error fetching Spectrum Surveys:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/bmlssurveys')
      .then(response => {
        setBmlsSurveys(response.data);
      })
      .catch(error => {
        console.error('Error fetching BMLS Surveys:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/surveys')
      .then(response => {
        setAllSurveys(response.data);
      })
      .catch(error => {
        console.error('Error fetching Surveys:', error);
      });
  }, []);



const data = {
  labels: ['Satisfaction', 'Quality of Service', 'Cleanliness', 'Professionalism','Attentiveness','Wait Time','Use Service Again','Recommend'],
  datasets: [
    {
      label: 'Average Ratings',
      backgroundColor: ['#003f5c', 'g#2f4b7c', '#665191', '#a05195', '#d45087','#f95d6a','#ff7c43', '#ffa600'],
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
      data: [
        calculateAverage(aamgSurveys, 'satisfaction'),
        calculateAverage(aamgSurveys, 'qualityOfService'),
        calculateAverage(aamgSurveys, 'cleanliness'),
        calculateAverage(aamgSurveys, 'professionalism'),
        calculateAverage(aamgSurveys, 'attentiveness'),
        calculateAverage(aamgSurveys, 'waitTime'),
        calculateAverage(aamgSurveys, 'serviceAgain'),
        calculateAverage(aamgSurveys, 'recommendation'),
      ],
    },
  ],
};

const spectrumData = {
  labels: ['Satisfaction', 'Quality of Service', 'Cleanliness', 'Professionalism','Attentiveness','Wait Time','Use Service Again','Recommend'],
  datasets: [
    {
      label: 'Average Ratings',
      backgroundColor: ['#003f5c', 'g#2f4b7c', '#665191', '#a05195', '#d45087','#f95d6a','#ff7c43', '#ffa600'],
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
      data: [
        calculateAverage(spectrumSurveys, 'satisfaction'),
        calculateAverage(spectrumSurveys, 'qualityOfService'),
        calculateAverage(spectrumSurveys, 'cleanliness'),
        calculateAverage(spectrumSurveys, 'professionalism'),
        calculateAverage(spectrumSurveys, 'attentiveness'),
        calculateAverage(spectrumSurveys, 'waitTime'),
        calculateAverage(spectrumSurveys, 'serviceAgain'),
        calculateAverage(spectrumSurveys, 'recommendation'),
      ],
    },
  ],
};

const bmlsData = {
  labels: ['Satisfaction', 'Quality of Service', 'Cleanliness', 'Professionalism','Attentiveness','Wait Time','Use Service Again','Recommend'],
  datasets: [
    {
      label: 'Average Ratings',
      backgroundColor: ['#003f5c', 'g#2f4b7c', '#665191', '#a05195', '#d45087','#f95d6a','#ff7c43', '#ffa600'],
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
      data: [
        calculateAverage(bmlsSurveys, 'satisfaction'),
        calculateAverage(bmlsSurveys, 'qualityOfService'),
        calculateAverage(bmlsSurveys, 'cleanliness'),
        calculateAverage(bmlsSurveys, 'professionalism'),
        calculateAverage(bmlsSurveys, 'attentiveness'),
        calculateAverage(bmlsSurveys, 'waitTime'),
        calculateAverage(bmlsSurveys, 'serviceAgain'),
        calculateAverage(bmlsSurveys, 'recommendation'),
      ],
    },
  ],
};

const allSurveyData = {
  labels: ['Satisfaction', 'Quality of Service', 'Cleanliness', 'Professionalism','Attentiveness','Wait Time','Use Service Again','Recommend'],
  datasets: [
    {
      label: 'Average Ratings',
      backgroundColor: ['#003f5c', 'g#2f4b7c', '#665191', '#a05195', '#d45087','#f95d6a','#ff7c43', '#ffa600'],
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
      data: [
        calculateAverage(allSurveys, 'satisfaction'),
        calculateAverage(allSurveys, 'qualityOfService'),
        calculateAverage(allSurveys, 'cleanliness'),
        calculateAverage(allSurveys, 'professionalism'),
        calculateAverage(allSurveys, 'attentiveness'),
        calculateAverage(allSurveys, 'waitTime'),
        calculateAverage(allSurveys, 'serviceAgain'),
        calculateAverage(allSurveys, 'recommendation'),
      ],
    },
  ],
};

function calculateAverage(data, field) {
  const sum = data.reduce((accumulator, survey) => accumulator + survey[field], 0);
  const average = sum / data.length;
  return average.toFixed(2); // Limit the decimal places to 2
}





  return (
    <>
     <h3>Group Customer Surveys/Feedback</h3>
     <Row xs={1} md={2} lg={3} className="g-4 custom-row mx-2">
      {/* xs={1} for extra small screens (1 card per row) */}
      {/* md={2} for medium screens (2 cards per row) */}
      {/* lg={3} for large screens (3 cards per row) */}
      <Col>
        <Card className="border rounded w-100 custom-card-xs" style={{ width: '18rem' }} >
          <Card.Body>
            <Card.Title className="text-center border-bottom">AAMG Surveys</Card.Title>
            <Card.Text>
              <div>
                <FacilitySurveyChart data={data}/>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="border rounded w-100" style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title className="text-center border-bottom">Spectrum Surveys</Card.Title>
            <Card.Text>
              <div>
                <FacilitySurveyChart data={spectrumData}/>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="border rounded w-100" style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title className="text-center border-bottom">BMLS Surveys</Card.Title>
            <Card.Text>
              <div>
                <FacilitySurveyChart data={bmlsData}/>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="border rounded w-100" style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title className="text-center border-bottom">All Surveys</Card.Title>
            <Card.Text>
              <div>
                <FacilitySurveyChart data={allSurveyData}/>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      </Row>
    </>
  )
}

export default SurveyDashboardCards