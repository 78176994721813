import React from 'react';
import interhealthlogo from '../images/interhealthLogoSm.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const InterHealthLogo = () => {
  return (
    <div>
      <img src={interhealthlogo} alt="interhealthlogo" className="img-fliud"/>
    </div>
  )
}

export default InterHealthLogo
