import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SurveyNav from '../components/SurveyNav';
import Login from '../components/Login';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <>
        <div className="container mt-5">
            <Login />
        </div>
    </>
  )
}

export default Home
