// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sidebar.css */
.sidebar {
    width: 250px;
    transition: width 0.3s ease-in-out;
  }
  
  .sidebar.collapsed {
    width: 80px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,YAAY;IACZ,kCAAkC;EACpC;;EAEA;IACE,WAAW;EACb","sourcesContent":["/* Sidebar.css */\n.sidebar {\n    width: 250px;\n    transition: width 0.3s ease-in-out;\n  }\n  \n  .sidebar.collapsed {\n    width: 80px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
