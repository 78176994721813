import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoutes from './components/PrivateRoutes';
import SurveyForm from './pages/SurveyForm';
import ThankYou from './pages/ThankYou';
import Home from './pages/Home';
import DashboardLayout from './components/DashboardLayout';
import SurveyDashboard from './pages/SurveyDashboard';
import InventoryDashboard from './pages/InventoryDashboard';
import Users from './pages/Users';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/survey/:customerName/:facilityId/:serviceDate" element={<SurveyForm />} />
          <Route path="/thank-you" element={<ThankYou />} />
          
          <Route element={<PrivateRoutes/>}>
            <Route path="/surveydashboard" element={<DashboardLayout><SurveyDashboard /></DashboardLayout>} />
            <Route path="/inventorydashboard" element={<DashboardLayout><InventoryDashboard /></DashboardLayout>} />
            <Route path="/users" element={<DashboardLayout><Users /></DashboardLayout>} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );

 
}

export default App;