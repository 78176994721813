import React from 'react';

const ThankYou = () => {
  return (
    <div className="container mt-5">
      <h2>Thank You!</h2>
      <p>Your survey has been submitted.</p>
    </div>
  );
};

export default ThankYou;
