import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
  return (
    <>
        <div className="bg-secondary bg-gradient text-white my-5">
            <div className="footer-copyright text-center text-white py-3">© 2023 Copyright:
            <a href="/" className="text-decoration-none text-white"> Interhealth Bahamas Ltd.</a>
            </div>
           

        </div>
    </>
  )
}

export default Footer