import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ViewModal from './ViewModal'; 
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './SurveysTable.css';
import '@popperjs/core';
import 'bootstrap/dist/js/bootstrap.bundle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'react-bootstrap';
import * as XLSX from 'xlsx/xlsx.mjs';

const { SearchBar } = Search; // Destructure the SearchBar component

const SurveysTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState([]);
  const [surveys, setSurveys] = useState([]);

  const fetchUpdatedSurveys = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+'/surveys');
      setSurveys(response.data);
    } catch (error) {
      console.error('Error fetching surveys:', error);
    }
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/surveys')
      .then(response => {
        setSurveys(response.data);
      })
      .catch(error => {
        console.error('Error fetching surveys:', error);
      });
  }, []);

  function exportToExcel(data, columns, fileName) {
    const worksheetData = data.map((item) => {
      const rowData = {};
      columns.forEach((column) => {
        // Use the column accessor as the property key
        const key = column.accessor;
        rowData[key] = item[key];
      });
      return rowData;
    });
  
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }

  const excelColumns = [
    {
      Header: 'Customer Name',
      accessor: 'customerName',
      exportAccessor: (row) => row.customerName, // Accessor function for exporting
    },
    {
      Header: 'Facility ID',
      accessor: 'facilityId',
      exportAccessor: (row) => row.facilityId, // Accessor function for exporting
    },
    {
      Header: 'Service Date',
      accessor: 'serviceDate',
      exportAccessor: (row) => row.serviceDate, // Accessor function for exporting
    },
    {
      Header: 'Satisfaction',
      accessor: 'satisfaction',
      exportAccessor: (row) => row.satisfaction, // Accessor function for exporting
    },
    {
      Header: 'Quality Of Service',
      accessor: 'qualityOfService',
      exportAccessor: (row) => row.qualityOfService, // Accessor function for exporting
    },
    {
      Header: 'Cleanliness',
      accessor: 'cleanliness',
      exportAccessor: (row) => row.cleanliness, // Accessor function for exporting
    },
    {
      Header: 'Profesionalism',
      accessor: 'profesionalism',
      exportAccessor: (row) => row.profesionalism, // Accessor function for exporting
    },
    {
      Header: 'Attentiveness',
      accessor: 'attentiveness',
      exportAccessor: (row) => row.attentiveness, // Accessor function for exporting
    },
    {
      Header: 'Wait Time',
      accessor: 'waitTime',
      exportAccessor: (row) => row.waitTime, // Accessor function for exporting
    },
    {
      Header: 'Service Again',
      accessor: 'serviceAgain',
      exportAccessor: (row) => row.serviceAgain, // Accessor function for exporting
    },
    {
      Header: 'Recommendation',
      accessor: 'recommendation',
      exportAccessor: (row) => row.recommendation, // Accessor function for exporting
    },
    {
      Header: 'Employee Feedback',
      accessor: 'employeeFeedback',
      exportAccessor: (row) => row.employeeFeedback, // Accessor function for exporting
    },
    {
      Header: 'Additional Feedback',
      accessor: 'additionalFeedback',
      exportAccessor: (row) => row.additonalFeedback, // Accessor function for exporting
    },
    {
      Header: 'Survey Submit Date',
      accessor: 'surveySubmitDate',
      exportAccessor: (row) => row.suveySubmitDate, // Accessor function for exporting
    },
  ];


  const columns = [
    { dataField: 'customerName', text: 'Customer Name', sort: true },
    { dataField: 'facilityId', text: 'Facility ID', sort: true },
    { dataField: 'serviceDate', text: 'Service Date', sort: true }, 
    // Add more columns as needed
    {
      text: 'Actions',
      formatter: (cell, row) => (
        <div className="d-flex justify-content-between">
          <Button variant="info" className="me-2" onClick={() => handleView(row)}>
          <i class="bi bi-eye-fill"></i> View
          </Button>
          
        </div>
      ),
    },
  ];

  const handleView = survey => {
    setSelectedSurvey(survey);
    setShowModal(true);
  };

 
  const handleTableDownload = (data, columns) => {
    exportToExcel(data, columns, 'surveys_table');
  };
  

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSurvey([]);
  };

  return (
    <ToolkitProvider
      keyField="_id"
      data={surveys}
      columns={columns}
      search // Enable search feature
    >
      {props => (
        <div>
          <ToastContainer />
          <div className="d-flex align-items-end justify-content-between mb-2">
            <div className="d-flex gap-1">
              <div>
              
              <button className="btn btn-success" onClick={() => handleTableDownload(surveys, excelColumns)}><i className="bi bi-file-earmark-spreadsheet"></i> Download Table</button>
              </div>
            </div>
            <div>
              <label className="me-2 mt-4 "><i className="bi bi-search"></i></label>
              <Search.SearchBar {...props.searchProps} placeholder="Search surveys..." />
            </div>
          </div>
          <BootstrapTable
            {...props.baseProps}
            pagination={paginationFactory()}
          />
        {/* View Modal */}
      <ViewModal show={showModal} onHide={handleCloseModal} survey={selectedSurvey} />
      <ToastContainer />
        </div>
      )}
      
    </ToolkitProvider>

    

  );
};

export default SurveysTable;
