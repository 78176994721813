import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

const Breadcrumbs = ({ crumbs }) => {
  return (
    <Breadcrumb className="p-2">
      {crumbs.map((crumb, index) => (
        <Breadcrumb.Item
          key={index}
          active={index === crumbs.length - 1}
          href={crumb.path}
        >
          {crumb.label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;