import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import UsersList from '../components/UserList';

const Users = () => {
  return (
    <>
        <div className="px-2 bg-light">
            <div className="bg-white border rounded my-4 p-2">
                <UsersList />
            </div>
        </div>
    </>
  )
}

export default Users