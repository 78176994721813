import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const FacilitySurveyChart = ({ data }) => {
  console.log("Data received in FacilitySurveyChart:", data);
  const options = {
    scales: {
      y: {
        type: 'linear',
        beginAtZero: true,
        max: 5, // Set the maximum Y-axis value to 5
      },
    },
    maintainAspectRatio: false,
  };
  return (
    <div className="facility-chart" style={{ height: '250px' }}>
      <Bar data={data} options={options} />
      
    </div>
  );
};

export default FacilitySurveyChart;
