import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

const ViewModal = ({ show, onHide, survey }) => {
  return (
    <>
      <Modal show={show} onHide={onHide} >
      <Modal.Header closeButton>
        <Modal.Title>View Survey Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Customer Name: <strong>{survey.customerName}</strong></p>
        <p>Facility ID: <strong>{survey.facilityId}</strong></p>
        <p>ServiceDate: <strong>{survey.serviceDate}</strong></p>
        <p>Satisfaction: <strong>{survey.satisfaction}</strong></p>
        <p>Quality Of Service: <strong>{survey.qualityOfService}</strong></p>
        <p>Cleanliness: <strong>{survey.cleanliness}</strong></p>
        <p>Professionalism: <strong>{survey.professionalism}</strong></p>
        <p>Attentiveness: <strong>{survey.attentiveness}</strong></p>
        <p>Wait Time: <strong>{survey.waitTime}</strong></p>
        <p>Service Again: <strong>{survey.serviceAgain}</strong></p>
        <p>Recommendation: <strong>{survey.recommendation}</strong></p>
        <p>Employee Feedback: <strong>{survey.employeeFeedback}</strong></p>
        <p>Additional Feeback: <strong>{survey.additionalFeedback}</strong></p>
        <p>Survey Submit Date: <strong>{survey.surveySubmitDate}</strong></p>
        
      </Modal.Body>
      <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
      </Modal>
    </>
  )
}

export default ViewModal
