import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Sidebar.css'; 
import logo from "../images/interhealthLogoSm.png";
// import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { BsPerson, BsBuilding, BsFileText } from 'react-icons/bs';



const Sidebar = ({ isCollapsed }) => {
  const { accessLevel } = useAuth();

  const renderLogo = () => {
    if (isCollapsed) {
      return <span className="text-dark">IHB</span>;
    } else {
      return <img src={logo} alt="Logo" />;
    }
  };
 
  return (
    <nav className={`sidebar ${isCollapsed ? 'collapsed' : ''} bg-light bg-gradient border rounded`}>
      <div className="logo bg-light bg-gradient text-white py-4 px-2 border-bottom">
        {renderLogo()}
      </div>
      
      <Nav className="flex-column py-4">
      <Nav.Item>
          <Link to="/dashboard" className="nav-link">{isCollapsed ? <span><h3><BsBuilding /></h3></span> : <span><BsBuilding /> Dashboard</span>}</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/surveydashboard" className="nav-link">{isCollapsed ? <span><h3><BsBuilding /></h3></span> : <span><BsBuilding /> Surveys</span>}</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/inventorydashboard" className="nav-link">{isCollapsed ? <span><h3><BsBuilding /></h3></span> : <span><BsBuilding /> Inventory</span>}</Link>
        </Nav.Item>
        <Nav.Item>
          {accessLevel === 1 && (
            <Link to="/users" className="nav-link">{isCollapsed ? <span><h3><BsPerson /></h3></span> : <span><BsPerson /> User Management</span>}</Link>
          )}
        </Nav.Item>
      </Nav>
    </nav>
  );
};

export default Sidebar;
