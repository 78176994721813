import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Survey = ({onSubmit}) => {
    const [satisfaction, setSatisfaction] = useState(5);
    const [qualityOfService, setQualityOfService] = useState(5);
    const [cleanliness, setCleanliness] = useState(5);
    const [professionalism, setProfessionalism] = useState(5);
    const [attentiveness, setAttentiveness] = useState(5);
    const [waitTime, setWaitTime] = useState(5);
    const [serviceAgain, setServiceAgain] = useState(5);
    const [recommendation, setRecommendation] = useState(5);
    const [employeeFeedback, setEmployeeFeedback] = useState('');
    const [additionalFeedback, setAdditionalFeedback] = useState('');
    
    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Here, you can handle the form submission, e.g., send data to the server
        onSubmit({ 
          satisfaction,
          qualityOfService,
          cleanliness,
          professionalism,
          attentiveness,
          waitTime,
          serviceAgain,
          recommendation,
          employeeFeedback,
          additionalFeedback, });
        

         // Reset the form after submission
        setSatisfaction(5);
        setQualityOfService(5);
        setCleanliness(5);
        setProfessionalism(5);
        setAttentiveness(5);
        setWaitTime(5);
        setServiceAgain(5);
        setRecommendation(5);
        setEmployeeFeedback('');
        setAdditionalFeedback('');
        };

  return (
    <>     
      <form onSubmit={handleFormSubmit}>
        {/* ... (other form elements) */}
        <div className="mb-3 d-flex flex-column text-start">
          <p>1. Overall, how satisfied were you with your experience?</p>
          <div className="d-flex flex-column gap-1 mb-3 border-bottom pb-3 small">
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="satisfaction"
                value="5"
                checked={satisfaction === 5}
                onChange={(e) => setSatisfaction(Number(e.target.value))}
                />
                <label className="form-check-label">Very satisfied (5)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="satisfaction"
                value="4"
                checked={satisfaction === 4}
                onChange={(e) => setSatisfaction(Number(e.target.value))}
                />
                <label className="form-check-label">Satisfied (4)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="satisfaction"
                value="3"
                checked={satisfaction === 3}
                onChange={(e) => setSatisfaction(Number(e.target.value))}
                />
                <label className="form-check-label">Neutral (3)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="satisfaction"
                value="2"
                checked={satisfaction === 2}
                onChange={(e) => setSatisfaction(Number(e.target.value))}
                />
                <label className="form-check-label">Dissatisfied (2)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="satisfaction"
                value="1"
                checked={satisfaction === 1}
                onChange={(e) => setSatisfaction(Number(e.target.value))}
                />
                <label className="form-check-label">Very Dissatisfied (1)</label>
            </div>
          </div>
        </div>
        <div className="mb-3 d-flex flex-column text-start">
          <p>2. How satisfied were you with the quality of service received at our facility?</p>
          <div className="d-flex flex-column gap-1 border-bottom pb-3 small">
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="qualityOfService"
                value="5"
                checked={qualityOfService === 5}
                onChange={(e) => setQualityOfService(Number(e.target.value))}
                />
                <label className="form-check-label">Very satisfied (5)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="qualityOfService"
                value="4"
                checked={qualityOfService === 4}
                onChange={(e) => setQualityOfService(Number(e.target.value))}
                />
                <label className="form-check-label">Satisfied (4)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="qualityOfservice"
                value="3"
                checked={qualityOfService === 3}
                onChange={(e) => setQualityOfService(Number(e.target.value))}
                />
                <label className="form-check-label">Neutral (3)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="qualityOfService"
                value="2"
                checked={qualityOfService === 2}
                onChange={(e) => setQualityOfService(Number(e.target.value))}
                />
                <label className="form-check-label">Dissatisfied (2)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="qualityOfService"
                value="1"
                checked={qualityOfService === 1}
                onChange={(e) => setQualityOfService(Number(e.target.value))}
                />
                <label className="form-check-label">Very Dissatisfied (1)</label>
            </div>
          </div>
        </div>
        <div className="mb-3 d-flex flex-column text-start">
          <p>3. How satisfied were you with the cleanliness and hygiene at our medical facility?</p>
          <div className="d-flex flex-column  gap-1 border-bottom pb-3 small">
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="cleanliness"
                value="5"
                checked={cleanliness === 5}
                onChange={(e) => setCleanliness(Number(e.target.value))}
                />
                <label className="form-check-label">Very satisfied (5)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="cleanliness"
                value="4"
                checked={cleanliness === 4}
                onChange={(e) => setCleanliness(Number(e.target.value))}
                />
                <label className="form-check-label">Satisfied (4)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="cleanliness"
                value="3"
                checked={cleanliness === 3}
                onChange={(e) => setCleanliness(Number(e.target.value))}
                />
                <label className="form-check-label">Neutral (3)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="cleanliness"
                value="2"
                checked={cleanliness === 2}
                onChange={(e) => setCleanliness(Number(e.target.value))}
                />
                <label className="form-check-label">Dissatisfied (2)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="cleanliness"
                value="1"
                checked={cleanliness === 1}
                onChange={(e) => setCleanliness(Number(e.target.value))}
                />
                <label className="form-check-label">Very Dissatisfied (1)</label>
            </div>
          </div>
        </div>
        <div className="mb-3 d-flex flex-column text-start">
          <p>4. How satisfied were you with the professionalism of our staff?</p>
          <div className="d-flex flex-column gap-1 border-bottom pb-3 small">
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="professionalism"
                value="5"
                checked={professionalism === 5}
                onChange={(e) => setProfessionalism(Number(e.target.value))}
                />
                <label className="form-check-label">Very satisfied (5)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="professionalism"
                value="4"
                checked={professionalism === 4}
                onChange={(e) => setProfessionalism(Number(e.target.value))}
                />
                <label className="form-check-label">Satisfied (4)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="professionalism"
                value="3"
                checked={professionalism === 3}
                onChange={(e) => setProfessionalism(Number(e.target.value))}
                />
                <label className="form-check-label">Neutral (3)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="professionalism"
                value="2"
                checked={professionalism === 2}
                onChange={(e) => setProfessionalism(Number(e.target.value))}
                />
                <label className="form-check-label">Dissatisfied (2)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="professionalism"
                value="1"
                checked={professionalism === 1}
                onChange={(e) => setProfessionalism(Number(e.target.value))}
                />
                <label className="form-check-label">Very Dissatisfied (1)</label>
            </div>
          </div>
        </div>
        <div className="mb-3 d-flex flex-column text-start">
          <p>5. How satisfied were you with the attentiveness shown toward your concerns?</p>
          <div className="d-flex flex-column  gap-1 border-bottom pb-3 small">
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="attentiveness"
                value="5"
                checked={attentiveness === 5}
                onChange={(e) => setAttentiveness(Number(e.target.value))}
                />
                <label className="form-check-label">Very satisfied (5)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="attentiveness"
                value="4"
                checked={attentiveness === 4}
                onChange={(e) => setAttentiveness(Number(e.target.value))}
                />
                <label className="form-check-label">Satisfied (4)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="attentiveness"
                value="3"
                checked={attentiveness === 3}
                onChange={(e) => setAttentiveness(Number(e.target.value))}
                />
                <label className="form-check-label">Neutral (3)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="attentiveness"
                value="2"
                checked={attentiveness === 2}
                onChange={(e) => setAttentiveness(Number(e.target.value))}
                />
                <label className="form-check-label">Dissatisfied (2)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="attentiveness"
                value="1"
                checked={attentiveness === 1}
                onChange={(e) => setAttentiveness(Number(e.target.value))}
                />
                <label className="form-check-label">Very Dissatisfied (1)</label>
            </div>
          </div>
        </div>
        <div className="mb-3 d-flex flex-column text-start">
          <p>6. How long did you have to wait past the appointment time to obtain service?</p>
          <div className="d-flex flex-column  gap-1 border-bottom pb-3 small">
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="waitTime"
                value="5"
                checked={waitTime === 5}
                onChange={(e) => setWaitTime(Number(e.target.value))}
                />
                <label className="form-check-label">0 – 15 minutes (5)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="waitTime"
                value="4"
                checked={waitTime === 4}
                onChange={(e) => setWaitTime(Number(e.target.value))}
                />
                <label className="form-check-label">15 – 30 minutes (4)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="waitTime"
                value="3"
                checked={waitTime === 3}
                onChange={(e) => setWaitTime(Number(e.target.value))}
                />
                <label className="form-check-label">30 – 45 minutes (3)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="waitTime"
                value="2"
                checked={waitTime === 2}
                onChange={(e) => setWaitTime(Number(e.target.value))}
                />
                <label className="form-check-label">45 – 60 minutes (2)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="waitTime"
                value="1"
                checked={waitTime === 1}
                onChange={(e) => setWaitTime(Number(e.target.value))}
                />
                <label className="form-check-label">more than 60 minutes (1)</label>
            </div>
          </div>
        </div>
        <div className="mb-3 d-flex flex-column text-start">
          <p>7. How likely are you to use our services again? </p>
          <div className="d-flex flex-column  gap-1 border-bottom pb-3 small">
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="serviceAgain"
                value="5"
                checked={serviceAgain === 5}
                onChange={(e) => setServiceAgain(Number(e.target.value))}
                />
                <label className="form-check-label">Very Likley (5)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="serviceAgain"
                value="4"
                checked={serviceAgain === 4}
                onChange={(e) => setServiceAgain(Number(e.target.value))}
                />
                <label className="form-check-label">Likely (4)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="serviceAgain"
                value="3"
                checked={serviceAgain === 3}
                onChange={(e) => setServiceAgain(Number(e.target.value))}
                />
                <label className="form-check-label">Neither likely no unlikely (3)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="serviceAgain"
                value="2"
                checked={serviceAgain === 2}
                onChange={(e) => setServiceAgain(Number(e.target.value))}
                />
                <label className="form-check-label">Unlikely (2)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="serviceAgain"
                value="1"
                checked={serviceAgain === 1}
                onChange={(e) => setServiceAgain(Number(e.target.value))}
                />
                <label className="form-check-label">Very Unlikely (1)</label>
            </div>
          </div>
        </div>
        <div className="mb-3 d-flex flex-column text-start">
          <p>8. How likely are you to recommend us to family or friends?</p>
          <div className="d-flex flex-column  gap-1 border-bottom pb-3 small">
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="recommendation"
                value="5"
                checked={recommendation === 5}
                onChange={(e) => setRecommendation(Number(e.target.value))}
                />
                <label className="form-check-label">Very Likley (5)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="recommendation"
                value="4"
                checked={recommendation === 4}
                onChange={(e) => setRecommendation(Number(e.target.value))}
                />
                <label className="form-check-label">Likely (4)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="recommendation"
                value="3"
                checked={recommendation === 3}
                onChange={(e) => setRecommendation(Number(e.target.value))}
                />
                <label className="form-check-label">Neither likely no unlikely (3)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="recommendation"
                value="2"
                checked={recommendation === 2}
                onChange={(e) => setRecommendation(Number(e.target.value))}
                />
                <label className="form-check-label">Unlikely (2)</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="recommendation"
                value="1"
                checked={recommendation === 1}
                onChange={(e) => setRecommendation(Number(e.target.value))}
                />
                <label className="form-check-label">Very Unlikely (1)</label>
            </div>
          </div>
        </div>

        <div className="mb-3 text-start">
          <label htmlFor="additionalFeedback" className="form-label">
          9. Was there an employee (or employees) who made your service at our facility better? If yes, who:
          </label>
          <textarea
            className="form-control"
            id="employeeFeedback"
            rows="3"
            value={employeeFeedback}
            onChange={(e) => setEmployeeFeedback(e.target.value)}
          />
        </div>
        <div className="mb-3 text-start">
          <label htmlFor="additionalFeedback" className="form-label">
            10. Do you have any additional feedback or any other suggestions for improving our services?
          </label>
          <textarea
            className="form-control"
            id="additionalFeedback"
            rows="3"
            value={additionalFeedback}
            onChange={(e) => setAdditionalFeedback(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </>
  );
};

export default Survey;
