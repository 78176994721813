import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Col, Row, Spinner, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './InventoryDashboardCards.css';
import ViewSpectrumInventoryModal from './ViewSpectrumInventoryModal';
import ViewBMLSInventoryModal from './ViewBMLSInventoryModal';

const InventoryDashboardCards = () => {
  const [spectrumInventory, setSpectrumInventory] = useState([]);
  const [bmlsInventory, setBMLSInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSpectrumInventoryModal, setShowSpectrumInventoryModal] = useState(false);
  const [showBMLSInventoryModal, setShowBMLSInventoryModal] = useState(false);

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const spectrumResponse = await axios.get(process.env.REACT_APP_SERVER_URL + '/spectruminventory');
        console.log(spectrumResponse.data.recordsets[0]);
        setSpectrumInventory(Array.isArray(spectrumResponse.data.recordsets) && spectrumResponse.data.recordsets.length > 0 
          ? spectrumResponse.data.recordsets[0] 
          : []);
        
        const bmlsResponse = await axios.get(process.env.REACT_APP_SERVER_URL + '/bmlsinventory');
        setBMLSInventory(Array.isArray(bmlsResponse.data.recordsets) && bmlsResponse.data.recordsets.length > 0 
          ? bmlsResponse.data.recordsets[0] 
          : []);

        setLoading(false);
      } catch (error) {
        setError('Error fetching inventory data');
        setLoading(false);
      }
    };

    fetchInventory();
  }, []);

  const calculateTotals = (inventory) => {
    const totalItems = Array.isArray(inventory) ? inventory.length : 0;
    const totalPrice = Array.isArray(inventory) ? inventory.reduce((sum, item) => sum + item.InventoryWholesale * item.InventoryQuantity, 0):0;
    return { totalItems, totalPrice };
  };

  const spectrumTotals = calculateTotals(spectrumInventory);
  const bmlsTotals = calculateTotals(bmlsInventory);

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const handleShowSpectrumInventoryModal = () => {
    setShowSpectrumInventoryModal(true);

  }

  const handleCloseSpectrumInventoryModal = () => {
    setShowSpectrumInventoryModal(false);
  }

  const handleShowBMLSInventoryModal = () => {
    setShowBMLSInventoryModal(true);

  }

  const handleCloseBMLSInventoryModal = () => {
    setShowBMLSInventoryModal(false);
  }


  return (
    <>
      <h3>Inventory Dashboard</h3>
      <Row xs={1} md={2} lg={3} className="g-4 custom-row mx-2">
        <Col>
          <Card className="border rounded w-100 custom-card-xs" style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title className="text-center border-bottom">SPECTRUM Inventory</Card.Title>
              <Card.Text>
                <p>Total Items: {spectrumTotals.totalItems}</p>
                <p>Total Wholesale Value: ${spectrumTotals.totalPrice.toFixed(2)}</p>
                {spectrumInventory.length > 0 ? (
                  <Button variant='success' onClick={handleShowSpectrumInventoryModal}>View Inventory</Button>
                ) : (
                  <p>No inventory data available</p>
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="border rounded w-100" style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title className="text-center border-bottom">BMLS Inventory</Card.Title>
              <Card.Text>
                <p>Total Items: {bmlsTotals.totalItems}</p>
                <p>Total Wholesale Value: ${bmlsTotals.totalPrice.toFixed(2)}</p>
                {bmlsInventory.length > 0 ? (
                  <Button variant='success' onClick={handleShowBMLSInventoryModal}>View Inventory</Button>
                ) : (
                  <p>No inventory data available</p>
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ViewSpectrumInventoryModal show={showSpectrumInventoryModal} onHide={handleCloseSpectrumInventoryModal} inventory={spectrumInventory} />
      <ViewBMLSInventoryModal show={showBMLSInventoryModal} onHide={handleCloseBMLSInventoryModal} inventory={bmlsInventory} />   
    </>
  );
};

export default InventoryDashboardCards;
