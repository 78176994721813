import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import InventoryDashboardCards from '../components/InventoryDashboardCards';

const InventoryDashboard = () => {
  const crumbs = [
    { label: 'Home', path: '/' },
    { label: 'Inventory Dashboard', path: '/inventorydashboard' },
  ];
  return (
    <div className="px-2 bg-light">
      <Breadcrumbs crumbs={crumbs} />
      <div className="">
        <InventoryDashboardCards />
      </div>
      <div className="bg-white border rounded my-4 p-2">
         
      </div>
    </div>
  )
}

export default InventoryDashboard
