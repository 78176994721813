import React from 'react';
import bmlslogo from '../images/bmlsLogoSm.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const BmlsLogo = () => {
  return (
    <div>
      <img src={bmlslogo} alt="bmlslogo" className="img-fluid"/>
    </div>
  )
}

export default BmlsLogo
