import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import * as XLSX from 'xlsx/xlsx.mjs';

const { SearchBar } = Search;

const ViewBMLSInventoryModal = ({ show, onHide, inventory }) => {
    const columns = [
        {
            dataField: 'Code',
            text: 'Code',
            sort: true,
          },
        {
          dataField: 'Description',
          text: 'Name',
          sort: true,
        },
        {
          dataField: 'InventoryQuantity',
          text: 'Quantity',
          sort: true,
        },
        {
            dataField: 'InventoryRetail',
            text: 'Retail Price',
            sort: true,
            formatter: (cell) => `$${cell.toFixed(2)}`,
          },
        {
          dataField: 'InventoryWholesale',
          text: 'Wholesale Cost',
          sort: true,
          formatter: (cell) => `$${cell.toFixed(2)}`,
        },
        {
            dataField: 'InventoryReorder',
            text: 'Reorder Level',
            sort: true,
          },
      ];

      const paginationOptions = {
        sizePerPage: 15,
        showTotal: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
      };

      const handleTableDownload = (data,columns) =>{
        exportToExcel(data, columns, 'bmls_inventory_table');

      }

      function exportToExcel(data, columns, fileName) {
        const worksheetData = data.map((item) => {
          const rowData = {};
          columns.forEach((column) => {
            // Use the column accessor as the property key
            const key = column.accessor;
            rowData[key] = item[key];
          });
          return rowData;
        });
      
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
      }

      const excelColumns = [
        {
          Header: 'Code',
          accessor: 'Code',
          exportAccessor: (row) => row.Code, // Accessor function for exporting
        },
        {
          Header: 'Description',
          accessor: 'Description',
          exportAccessor: (row) => row.Description, // Accessor function for exporting
        },
        {
          Header: 'Quantity',
          accessor: 'InventoryQuantity',
          exportAccessor: (row) => row.InventoryQuanity, // Accessor function for exporting
        },
        {
          Header: 'Retail Price',
          accessor: 'InventoryRetail',
          exportAccessor: (row) => row.InventoryRetail, // Accessor function for exporting
        },
        {
          Header: 'Wholesale Cost',
          accessor: 'InventoryWholesale',
          exportAccessor: (row) => row.InventoryWholesale, // Accessor function for exporting
        },
        {
          Header: 'Reorder Level',
          accessor: 'InventoryReorder',
          exportAccessor: (row) => row.InventoryReorder, // Accessor function for exporting
        },
        
      ];

  return (
    <>
      <Modal show={show} onHide={onHide} size='xl' centered>
      <Modal.Header closeButton>
        <Modal.Title>View Spectrum Inventory Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <ToolkitProvider
          keyField="Code"
          data={inventory}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div className="d-flex align-items-end justify-content-between mb-2">
                <div className="d-flex gap-1">
                    <div>
                    
                    <button className="btn btn-success" onClick={() => handleTableDownload(inventory, excelColumns)}><i className="bi bi-file-earmark-spreadsheet"></i> Download Table</button>
                    </div>
                </div>
                <div>
                    <label className="me-2 mt-4 "><i className="bi bi-search"></i></label>
                    <SearchBar {...props.searchProps} placeholder="Search Inventory" />
                </div>
              </div>
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory(paginationOptions)}
                striped
                hover
                condensed
              />
            </>
          )}
        </ToolkitProvider>

      </Modal.Body>
      <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
      </Modal>
    
    </>
    
  )
}

export default ViewBMLSInventoryModal